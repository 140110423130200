<template>
  <div class="container">
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="100"/>
        <el-table-column align="center" label="微信昵称" prop="nickName"/>
        <el-table-column align="center" label="上级微信昵称" prop="suNickName"/>
        <el-table-column align="center" label="订单名称" prop="orderName"/>
        <el-table-column align="center" label="订单号" prop="orderNum"/>
        <el-table-column align="center" label="订单金额" prop="orderMoney" width="100"/>
        <el-table-column align="center" label="佣金金额" prop="orderComm" width="100"/>
        <el-table-column align="center" label="订单类型" prop="orderType" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.orderType === '0' ? 'success' : ''">
              {{ scope.row.orderType === '0' ? '线上' : '线下' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status" width="150">
          <template slot-scope="scope">
            <el-tag :type="statusColor[scope.row.status]">{{ status[scope.row.status] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.status != 2" type="success" @click="handlerDetail(scope.row)"
                       icon="el-icon-delete" size="small ">处理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
      <el-dialog title="处理" :visible.sync="checkShow" :close-on-click-modal="false" width="36%">
        <div class="layout_sa">
          <div class="list">订单名称：{{ info.orderName }}</div>
          <div class="list">身订单号：{{ info.orderNum }}</div>
        </div>
        <div class="layout_sa">
          <div class="list">微信昵称: {{ info.nickName }}</div>
          <div class="list">上级微信昵称：{{ info.suNickName }}</div>
        </div>
        <div class="layout_sa">
          <div class="list">订单金额：{{ info.orderMoney }}</div>
          <div class="list">订单类型：{{ info.orderType === '0' ? '线上' : '线下' }}</div>
        </div>
        <div class="layout_sa">
          <div class="list">状态：
            <el-radio v-model="handlerStatus" label="3">手动到账</el-radio>
            <el-radio v-model="handlerStatus" label="4">已退回</el-radio>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="checkShow = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {suUserCommPage, suUserCommHandle} from "../../apis/user";
import {userCommStatus, userCommColor} from './types'

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      total: null,
      query: {
        current: 1,
        size: 10,
      },
      status: userCommStatus,
      statusColor: userCommColor,
      info: {},
      checkShow: false,
      handlerStatus: null
    };
  },
  created() {
    this.getData()
  },
  methods: {
    async submit() {
      if (!this.handlerStatus) return this.$message.warning('请选择状态')
      const res = await suUserCommHandle({id: this.info.id, status: this.handlerStatus})
      this.$message.success(res.msg || '操作成功')
      this.checkShow = false
      this.info = {}
      this.getData()
    },
    handlerDetail(item) {
      this.info = item
      this.checkShow = true
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.getData();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getData();
    },
    async getData() {
      this.loading = true;
      const res = await suUserCommPage(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  flex: 1;
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px #eee dashed;
}

.dialog-content {
  width: 100%;
}
</style>